import { MenuEntry } from '@polysky-libs/uikit'
import { ContextApi } from 'contexts/Localization/types'

const config: (t: ContextApi['t']) => MenuEntry[] = (t) => [
	{
    label: t('Vaults '),
    icon: 'VaultsIcon',
    href: '/',
  },
  {
    label: t('Farm Home'),
    icon: 'HomeIcon',
    href: '/farmhome',
  },
  {
    label: t('Trade'),
    icon: 'TradeIcon',
    items: [
      {
        label: t('Exchange'),
        href: 'https://quickswap.exchange/#/swap?outputCurrency=0xB1289f48E8d8Ad1532e83A8961f6E8b5a134661D',
      },
      {
        label: t('Liquidity'),
        href: 'https://quickswap.exchange/#/add/0xb1289f48e8d8ad1532e83a8961f6e8b5a134661d/ETH',
      },
    
    ],
  },
  {
    label: t('Farms'),
    icon: 'FarmIcon',
    href: '/farms',
  },
  {
    label: t('Pools'),
    icon: 'PoolIcon',
    href: '/pools',
  },
  
  {
    label: t('Info'),
    icon: 'InfoIcon',
    items: [
      {
        label: t('Quickswap chart'),
        href: 'https://info.quickswap.exchange/token/0xb1289f48e8d8ad1532e83a8961f6e8b5a134661d',
      },
      {
        label: t('Polygon explorer'),
        href: 'https://polygonscan.com/token/0xb1289f48e8d8ad1532e83a8961f6e8b5a134661d',
      },
    
    ],
  },
  
  {
    label: t('More'),
    icon: 'MoreIcon',
    items: [
      
      {
        label: t('Github'),
        href: 'https://github.com/polysky-finance',
      },
      {
        label: t('Docs'),
        href: "https://Polysky-finance.gitbook.io/",
      },
      
    ],
  },

];


export default config

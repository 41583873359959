import { Vault } from 'state/types'
import {fetchVaultLP, fetchVaultSingle} from './fetchPublicVaultData'

const fetchVault = async (vault: Vault): Promise<Vault> => {
  const vaultPublicData =vault.isSingle? await fetchVaultSingle(vault): await fetchVaultLP(vault)

  return { ...vault, ...vaultPublicData }
}

export default fetchVault

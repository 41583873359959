import tokens from './tokens'
import { FarmConfig } from './types'

const farms: FarmConfig[] = [
  /**
   * These 3 farms (PID 0, 251, 252) should always be at the top of the file.
   */
   // MATIC - SIRIUS APESWAP LP 0xdf641eaf424a9972e92afd91ac57d28ea5ba05b7
   {
    pid: 1,
    lpSymbol: 'SIRIUS - WMATIC LP',
	exchange: 'Quickswap',
	baseLiquidityUrl: 'https://quickswap.exchange/#',
    lpAddresses: {
      137: '0xeb9321b1a21f74c1c439e64771b4232743f2fa1b',
      80001: '',
    },
	multiplier:'50X',
	harvest: true,
    token: tokens.sirius,
    quoteToken: tokens.wmatic,
  },
  {
    pid: 2,
    lpSymbol: 'WMATIC - USDC LP',
	exchange: 'Quickswap',
	baseLiquidityUrl: 'https://quickswap.exchange/#',
    lpAddresses: {
      137: '0x6e7a5fafcec6bb1e78bae2a1f0b612012bf14827',
      80001: '',
    },
	multiplier:'0X',
	harvest: true,
    token: tokens.usdc,
    quoteToken: tokens.wmatic,
  }
  ,{
    pid: 3,
    lpSymbol: 'FISH - USDC LP',
	exchange: 'Quickswap',
	baseLiquidityUrl: 'https://quickswap.exchange/#',
    lpAddresses: {
      137: '0x0df9e46c0eaedf41b9d4bbe2cea2af6e8181b033',
      80001: '',
    },
	multiplier:'0X',
	harvest: true,
    token: tokens.fish,
    quoteToken: tokens.usdc,
  },
  {
    pid: 4,
    lpSymbol: 'SIRIUS - WMATIC',
	exchange: 'Apeswap',
	baseLiquidityUrl: 'https://app.apeswap.finance/',
    lpAddresses: {
      137: '0x0df9e46c0eaedf41b9d4bbe2cea2af6e8181b033',
      80001: '',
    },
	multiplier:'50X',
	harvest: true,
    token: tokens.sirius,
    quoteToken: tokens.wmatic,
  },
  {
    pid: 17,
    lpSymbol: 'BANANA - WMATIC',
	exchange: 'Apeswap',
	baseLiquidityUrl: 'https://app.apeswap.finance/',
    lpAddresses: {
      137: '0x034293F21F1cCE5908BC605CE5850dF2b1059aC0',
      80001: '',
    },
	multiplier:'0X',
	harvest: true,
    token: tokens.banana,
    quoteToken: tokens.wmatic,
  },
  {
    pid: 18,
    lpSymbol: 'WETH - WMATIC',
	exchange: 'Quickswap',
	baseLiquidityUrl: 'https://app.apeswap.finance/',
    lpAddresses: {
      137: '0xadbf1854e5883eb8aa7baf50705338739e558e5b',
      80001: '',
    },
	multiplier:'0X',
	harvest: true,
    token: tokens.weth,
    quoteToken: tokens.wmatic,
  },
  {
    pid: 19,
    lpSymbol: 'LINK - WETH',
	exchange: 'Quickswap',
	baseLiquidityUrl: 'https://app.apeswap.finance/',
    lpAddresses: {
      137: '0x5ca6ca6c3709e1e6cfe74a50cf6b2b6ba2dadd67',
      80001: '',
    },
	multiplier:'0X',
	harvest: true,
    token: tokens.link,
    quoteToken: tokens.weth,
  },
  {
    pid: 20,
    lpSymbol: 'LINK - WETH',
	exchange: 'Quickswap',
	baseLiquidityUrl: 'https://app.apeswap.finance/',
    lpAddresses: {
      137: '0x2cf7252e74036d1da831d11089d326296e64a728',
      80001: '',
    },
	multiplier:'0X',
	harvest: true,
    token: tokens.usdt,
    quoteToken: tokens.usdc,
  },
   {
    pid: 21,
    lpSymbol: 'QUICK - WMATIC',
	exchange: 'Quickswap',
	baseLiquidityUrl: 'https://app.apeswap.finance/',
    lpAddresses: {
      137: '0x019ba0325f1988213d448b3472fa1cf8d07618d7',
      80001: '',
    },
	multiplier:'0X',
	harvest: true,
    token: tokens.quick,
    quoteToken: tokens.wmatic,
  },
  {
    pid: 22,
    lpSymbol: 'DFYN - USDC',
	exchange: 'Quickswap',
	baseLiquidityUrl: 'https://app.apeswap.finance/',
    lpAddresses: {
      137: '0x4c38938e21cb9796932b0b0cc3f8a088f07b49b0',
      80001: '',
    },
	multiplier:'0X',
	harvest: true,
    token: tokens.dfyn,
    quoteToken: tokens.usdc,
  },
  {
    pid: 23,
    lpSymbol: 'WBTC - WETH',
	exchange: 'Quickswap',
	baseLiquidityUrl: 'https://app.apeswap.finance/',
    lpAddresses: {
      137: '0xdC9232E2Df177d7a12FdFf6EcBAb114E2231198D',
      80001: '',
    },
	multiplier:'0X',
	harvest: true,
    token: tokens.wbtc,
    quoteToken: tokens.weth,
  },
  {
    pid: 24,
    lpSymbol: 'DAI - USDC',
	exchange: 'Quickswap',
	baseLiquidityUrl: 'https://app.apeswap.finance/',
    lpAddresses: {
      137: '0xf04adbf75cdfc5ed26eea4bbbb991db002036bdd',
      80001: '',
    },
	multiplier:'0X',
	harvest: true,
    token: tokens.dai,
    quoteToken: tokens.usdc,
  },
]

export default farms

import tokens from './tokens'
import { VaultConfig } from './types'

const vaults: VaultConfig[] = [
  /**
   * These 3 farms (PID 0, 251, 252) should always be at the top of the file.
   */
   {
    pid: 3,
    lpSymbol: 'AUTO SIRIUS',  // Always write the token with a farm first. This is used for price calculation. Better method to be implemented later
	exchange: 'Quickswap',
	baseLiquidityUrl: 'https://quickswap.exchange/',
	strategyAddress:{
		137: '0xe5F50817191BD3A6B699b702d3cFB77524f991f7',
		80001: '',
	},
    lpAddresses: {
      137: '0xb1289f48e8d8ad1532e83a8961f6e8b5a134661d',
      80001: '',
    },
	masterChefAddress:{
		137: '0xD0023db30D1f4dB77e1049E79817B4D5dc571d15',
		80001: '',
	},
	spid: 0,
    token: tokens.sirius,
    quoteToken: tokens.sirius,
	rewardToken: tokens.sirius,
	emissionFunctionName: 'siriusPerBlock',
	emissionMultiplier: 1,
	isArchived: false,
	isSingle:true,
	allocPointName:'lastRewardBlock',
  }, 
   {
    pid: 2,
    lpSymbol: 'WMATIC - BANANA',  // Always write the token with a farm first. This is used for price calculation. Better method to be implemented later
	exchange: 'Apeswap',
	baseLiquidityUrl: 'https://app.apeswap.finance/',
	strategyAddress:{
		137: '0x935B06804E87CABB138e7A3bd044315dB7F497e4',
		80001: '',
	},
    lpAddresses: {
      137: '0x034293f21f1cce5908bc605ce5850df2b1059ac0',
      80001: '',
    },
	masterChefAddress:{
		137: '0x54aff400858Dcac39797a81894D9920f16972D1D',
		80001: '',
	},
	spid: 0,
    token: tokens.banana,
    quoteToken: tokens.wmatic,
	rewardToken: tokens.banana,
	emissionFunctionName: 'bananaPerSecond',
	emissionMultiplier: 2,
	isArchived: false,
	isSingle:false,
	allocPointName: 'allocPoint',
  },
]

export default vaults

import BigNumber from 'bignumber.js'
import { DEFAULT_GAS_LIMIT, DEFAULT_TOKEN_DECIMAL } from 'config'

const options = {
  gasLimit: DEFAULT_GAS_LIMIT,
}

export const stakeVault = async (vaultChefContract, pid, amount) => {
  const value = new BigNumber(amount).times(DEFAULT_TOKEN_DECIMAL).toString()

  const tx = await vaultChefContract.deposit(pid, value, options)
  const receipt = await tx.wait()
  return receipt.status
}

export const unstakeVault = async (vaultChefContract, pid, amount) => {
  const value = new BigNumber(amount).times(DEFAULT_TOKEN_DECIMAL).toString()
  const tx = await vaultChefContract.withdraw(pid, value, options)
  const receipt = await tx.wait()
  return receipt.status
}
